import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  signal,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  ConfigurationService,
  ExportRequest,
  InpageAliasService,
  Session,
  UserRoleService,
} from 'src/app/my-sedgwick';
import { SearchService } from 'src/app/my-sedgwick/services/search.service';
import {
  BaseComponent,
  LoadingState,
  SearchConfiguration,
  SelectOption,
  ValidatorsEx,
  deepCopy,
  isEmpty,
  srSpeak,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';

@Component({
  selector: 'app-detail-search-form',
  template: `
    @if(config.showParametersToggle) {
    <div
      class="tw-w-full tw-flex tw-flex-wrap app-pointer tw-justify-end tw-items-center"
      (click)="toggleExpand()"
      [attr.aria-expanded]="expanded"
      [attr.aria-label]="expanded ? 'Section expanded' : 'Section collasped'"
      tabindex="0"
    >
      <div class="tw-font-bold app-color-slate">Additional parameters</div>
      <mat-icon
        tabindex="0"
        class="expand-icon"
        [attr.aria-label]="
          expanded ? 'Collapse this section' : 'Expand this section'
        "
        >{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon
      >
    </div>
    }

    <app-loading-panel
      loadingStyle="Skeleton"
      [state]="searchConfig ? 'Loaded' : 'Loading'"
      [hidden]="!expanded"
    >
      <ng-template #content>
        <form
          [formGroup]="formGroup"
          class="tw-flex tw-flex-wrap app-pt2"
          *ngIf="searchConfig"
        >
          <app-text-control
            formControlName="claimNumber"
            [formGroup]="formGroup"
            label="Claim Number"
            *ngIf="config.claimNumber.visible"
            [ngClass]="config.claimNumber.classes"
            ngDefaultControl
            [maxLength]="30"
          />
          <app-text-control
            formControlName="firstName"
            [formGroup]="formGroup"
            label="First Name"
            ngDefaultControl
            [validationControl]="'First Name'"
            *ngIf="config.firstName.visible"
            [ngClass]="config.firstName.classes"
            [maxLength]="50"
          />
          <app-text-control
            formControlName="lastName"
            [formGroup]="formGroup"
            label="Last Name"
            ngDefaultControl
            [validationControl]="'Last Name'"
            *ngIf="config.lastName.visible"
            [ngClass]="config.lastName.classes"
            [maxLength]="50"
          />
          <app-text-control
            formControlName="employeeId"
            [formGroup]="formGroup"
            [label]="employeeIdAlias | titlecase"
            ngDefaultControl
            *ngIf="config.employeeId.visible"
            [ngClass]="config.employeeId.classes"
            [maxLength]="30"
          />
          <app-text-control
            formControlName="ssn"
            [formGroup]="formGroup"
            label="SSN"
            mask="000-00-0000"
            ngDefaultControl
            *ngIf="config.ssn.visible"
            [ngClass]="config.ssn.classes"
            [password]="true"
            [passwordToggle]="true"
          />
          <app-select-control
            formControlName="lob"
            [formGroup]="formGroup"
            label="Line of Business"
            [options]="searchConfig.lineOfBusiness"
            [multiple]="true"
            ngDefaultControl
            *ngIf="config.lob.visible"
            [ngClass]="config.lob.classes"
          />
          <app-select-control
            formControlName="alertType"
            [formGroup]="formGroup"
            label="Notification Type"
            [options]="searchConfig.alertType"
            ngDefaultControl
            [multiple]="true"
            *ngIf="config.alertType.visible"
            [ngClass]="config.alertType.classes"
          />
          <app-select-control
            formControlName="status"
            [formGroup]="formGroup"
            label="Status"
            [options]="searchConfig.status"
            ngDefaultControl
            [multiple]="true"
            *ngIf="config.status.visible"
            [ngClass]="config.status.classes"
          />

          <app-date-control
            formControlName="startDate"
            [formGroup]="formGroup"
            label="Begin Date"
            ngDefaultControl
            *ngIf="config.startDate.visible"
            [ngClass]="config.startDate.classes"
            [minValue]="maxBackDays"
          />
          <app-date-control
            formControlName="endDate"
            [formGroup]="formGroup"
            label="End Date"
            ngDefaultControl
            *ngIf="config.endDate.visible"
            [ngClass]="config.endDate.classes"
          ></app-date-control>
          <app-manager-selector
            formControlName="supervisor"
            [formGroup]="formGroup"
            label="Manager"
            ngDefaultControl
            *ngIf="config.supervisor.visible"
            [ngClass]="config.supervisor.classes"
            [managerLookupSupervisor]="config.managerLookupSupervisor"
            [managerLookupOmni]="config.managerLookupOmni"
          />

          <app-checkbox-control
            formControlName="includeIndirectReports"
            [formGroup]="formGroup"
            label="Include indirect reports"
            *ngIf="config.includeIndirect.visible"
            [ngClass]="config.includeIndirect.classes + ' tw-pt-1'"
          >
            ></app-checkbox-control
          >

          <app-select-control
            formControlName="payType"
            [formGroup]="formGroup"
            label="Pay Type"
            [options]="searchConfig.payType"
            [multiple]="true"
            ngDefaultControl
            *ngIf="config.payType.visible"
            class="tw-w-full md:tw-w-[25%]"
          />

          <ng-container *ngIf="dsVisible">
            <div class="tw-font-bold tw-text-[16px] tw-pb-2 tw-w-full">
              Disability
            </div>

            <app-select-control
              formControlName="dsClaimType"
              [formGroup]="formGroup"
              label="Claim Type"
              [options]="searchConfig.claimTypeDs"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            ></app-select-control>

            <app-select-control
              formControlName="dsClaimSubstatus"
              [formGroup]="formGroup"
              label="Claim Substatus"
              [options]="searchConfig.claimSubstatusDs"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            />
          </ng-container>

          <ng-container *ngIf="lvVisible">
            <div class="tw-font-bold tw-text-[16px] tw-pb-2 tw-w-full">
              Absence
            </div>

            <app-select-control
              formControlName="lvClaimType"
              [formGroup]="formGroup"
              label="Claim Type"
              [options]="searchConfig.claimTypeLv"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            />

            <app-select-control
              formControlName="lvAbsenceType"
              [formGroup]="formGroup"
              label="Absence Type"
              [options]="searchConfig.absenceTypeLv"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            />

            <app-select-control
              formControlName="lvClaimSubstatus"
              [formGroup]="formGroup"
              label="Claim Substatus"
              [options]="searchConfig.absenceSubstatusLv"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            />

            <app-select-control
              formControlName="lvCausedBy"
              [formGroup]="formGroup"
              label="Caused By"
              [options]="searchConfig.causedBy"
              [multiple]="true"
              ngDefaultControl
              class="tw-w-full md:tw-w-[25%]"
            />
          </ng-container>

          <app-action-row class="tw-w-full">
            <div
              right3
              class="app-color-warn"
              #searchbtndiv
              *ngIf="showEmptyMessage"
              aria-label="Error Enter at least one search criteria"
              tabindex="0"
            >
              <span>Error : Enter at least one search criteria</span>
            </div>
            <app-button
              right2
              emphasis="High"
              icon="search"
              type="submit"
              (click)="searchClick()"
              [loading]="loading === 'Loading'"
            >
              Search
            </app-button>

            <app-button right1 emphasis="Low" (click)="_clear()">
              Clear
            </app-button>
          </app-action-row>
        </form>
        <ng-container [ngTemplateOutlet]="accommodationTemplate"></ng-container>
      </ng-template>
    </app-loading-panel>
    <app-loading-panel
      [loadingStyle]="loadingImageSrc ? 'Image' : 'Skeleton'"
      [loadingImageSrc]="loadingImageSrc"
      [loadingHeight]="300"
      [state]="loading"
      [error]="_error"
      *ngIf="searchConfig"
    >
      <div init>
        <app-empty-state-label
          *ngIf="!config.initialSearch"
          [icon]="config.initialSearchIcon"
          [header]="config.initialSearchHeader"
          [message]="config.initialSearchMsg"
        >
        </app-empty-state-label>
      </div>

      <app-empty-state-label
        empty
        icon="list_alt"
        header="No Results"
        message="There are no results that match the search."
      >
      </app-empty-state-label>
      <ng-template #content>
        <ng-container [ngTemplateOutlet]="resultsTemplate"></ng-container>
      </ng-template>
    </app-loading-panel>
  `,
  styles: ``,
})
export class DetailSearchFormComponent
  extends BaseComponent
  implements OnInit, SearchRequest
{
  @ContentChild('results')
  resultsTemplate: TemplateRef<any>;

  @ContentChild('AccommodationMsg')
  accommodationTemplate: TemplateRef<any>;

  @ViewChild('searchbtndiv')
  searchElement: ElementRef;

  @Input()
  config = new DetailSearchConfiguration();

  @Input()
  excludeLeaveClaims: boolean;

  @Output()
  search = new EventEmitter<SearchRequest>();

  @Output()
  clear = new EventEmitter();

  @Input()
  loading: LoadingState = 'Init';

  @Input()
  loadingImageSrc: string = '';

  maxBackDays: Date = null;

  _error: string;

  formGroup = this.fb.group(
    {
      claimNumber: [null, [ValidatorsEx.alphanumdash]],
      firstName: ['', [Validators.minLength(2)]],
      lastName: ['', [Validators.minLength(2)]],
      startDate: [],
      endDate: [null, [ValidatorsEx.futureDate]],
      employeeId: [null, [ValidatorsEx.alphanumeric]],
      ssn: [],
      status: [],
      lob: [],
      alertType: [],
      dsClaimType: [],
      dsClaimSubstatus: [],
      lvClaimType: [],
      lvClaimSubstatus: [],
      lvAbsenceType: [],
      lvCausedBy: [],
      supervisor: [],
      includeIndirectReports: [],
      payType: [],
    },
    {
      validators: [
        ValidatorsEx.dateOrder(
          'startDate',
          'endDate',
          'Begin date must be before end date'
        ),
      ],
    }
  );

  dsVisible: boolean = false;

  lvVisible: boolean = false;

  parameters = signal(null);

  results = signal(null);

  count: number = 0;

  showEmptyMessage = false;

  employeeIdAlias: string = 'Employee Id';

  searchConfig: SearchConfiguration;

  originalSearchConfig: SearchConfiguration;

  maxSearchResults: number = null;

  expanded = true;

  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private aliases: InpageAliasService,
    private session: Session,
    public configurationService: ConfigurationService,
    public userRoleService: UserRoleService,
    private el: ElementRef
  ) {
    super();

    this.subs.sink = this.formGroup.valueChanges.subscribe((fg) => {
      const lobs = <string[]>fg.lob;
      if (lobs) {
        this.lvVisible = lobs.includes('LA') || lobs.includes('LV');
        this.dsVisible = lobs.includes('DS');
      }
    });

    this.formGroup.controls['firstName'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
    this.formGroup.controls['lastName'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
  }

  async ngOnInit() {
    //refreshing configs for usage in different pages ie:excludeLeave claims in comm center
    this.originalSearchConfig = await this.searchService.getConfiguration();
    this.searchConfig = { ...this.originalSearchConfig };

    if (this.excludeLeaveClaims) {
      this.searchConfig.lineOfBusiness =
        this.searchConfig.lineOfBusiness.filter((x) => x.value !== 'LV');
    }

    if (document.location.pathname === '/summary/open-claims') {
      this.formGroup.value.includeIndirectReports = true;
    }

    if (
      this.userRoleService.auditor ||
      this.session.user?.emulatorContext?.isEmulating
    ) {
      // clear out the existing search values anytime we start/stop emulating
      this.config.clearParameters = true;
    }

    const initialValue = Object.assign(
      this.formGroup.value,
      this.config.initialValue
    );

    this.expanded = this.config.expanded;

    if (this.config.limitLobsToLiability) {
      this.searchConfig.lineOfBusiness =
        this.searchConfig.lineOfBusiness.filter(
          (x) =>
            x.value === 'AU' ||
            x.value === 'GL' ||
            x.value === 'PR' ||
            x.value === 'WC'
        );
    }

    this.maxSearchResults =
      this.config.maxSearchResults ?? this.searchConfig.maxSearchResults;
    this.employeeIdAlias = await this.aliases.get('employeeIdSingular');

    if (this.employeeIdAlias === null || this.employeeIdAlias.trim() === '') {
      this.employeeIdAlias = 'Employee Id';
    }

    if (this.config.key) {
      this.parameters = this.state.getSignal(`${this.config.key}:parameters`);
      this.expanded = this.state.get(`${this.config.key}:expanded`);
    }

    if (this.expanded === null) {
      this.expanded = this.config.expanded;
    }

    // set the visibility of the controls based on roles
    if (this.config.setDefaultVisibilitySettings) {
      this.setVisibility();
    }

    // set the form to the parameter signal or initial value from config
    if (this.config.clearParameters) {
      this.parameters.set(null);
    }

    const p = this.parameters();

    this.formGroup.setValue(p ?? initialValue);

    // calling setResults to set loading panel.
    // else, run the search if configured for initial search
    // only do the search agian if we are not an auditor and we are not emulating
    if (
      this.config.initialSearch ||
      (p &&
        !this.userRoleService.auditor &&
        !this.session.user?.emulatorContext?.isEmulating)
    ) {
      this.searchClick();
    }

    this.config.managerLookupOmni =
      this.session.user.roles.omni || this.session.user.roles.overseer;

    if (this.config.maxBackDays) {
      this.maxBackDays = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - this.config.maxBackDays
      );
    }
    this.formGroup
      .get('startDate')
      .addValidators([
        ValidatorsEx.minDate(
          this.config.maxBackDays ? this.config.maxBackDays : 730
        ),
        ValidatorsEx.futureDate(),
      ]);
    this.formGroup
      .get('endDate')
      .addValidators([
        ValidatorsEx.minDate(
          this.config.maxBackDays ? this.config.maxBackDays : 730
        ),
        ValidatorsEx.futureDate(),
      ]);
  }

  setVisibility() {
    this.config.employeeId.widthPercent = 25;

    // these fields require supervisor being enabled
    // by the client. If we are showing these fields,
    // confirm supervisor is enabled.
    const hasSupervisor =
      this.session.user.supervMatchType && !this.userRoleService.auditor
        ? true
        : false;
    const isHrClient = this.session.user.clientType === 'HR' ? true : false;

    this.config.employeeId.visible = this.config.employeeId.visible
      ? isHrClient
      : false;
    this.config.ssn.visible = this.config.ssn.visible
      ? this.session.user.hasSsnSearch
      : false;
    this.config.supervisor.visible = this.config.supervisor.visible
      ? hasSupervisor
      : false;
    this.config.includeIndirect.visible = this.config.includeIndirect.visible
      ? hasSupervisor
      : false;

    if (!this.config.employeeId.visible) {
      this.config.firstName.widthPercent = 35;
      this.config.lastName.widthPercent = 35;
    }

    if (this.session.user.roles.controller) {
      this.config.includeIndirect.visible = false;
      this.config.supervisor.visible = false;
      this.config.employeeId.visible = false;
    }

    if (this.config.ssn.visible) {
      this.config.claimNumber.widthPercent = 20;
      this.config.firstName.widthPercent = 20;
      this.config.lastName.widthPercent = 20;
      this.config.employeeId.widthPercent = 20;
      this.config.ssn.widthPercent = 20;
    } else {
      this.config.claimNumber.widthPercent = 25;
      this.config.firstName.widthPercent = 25;
      this.config.lastName.widthPercent = 25;
      this.config.employeeId.widthPercent = 25;
      this.config.ssn.widthPercent = 0;
    }

    this.config.supervisor.widthPercent = 30;
  }

  /**
   * Gets input parameters if the form is valid. The multiselect
   * inputs are transformed to always have values. If the user
   * does not pick a value, then all values are passed.
   */
  getParameters(): any {
    if (!this.formGroup.valid) {
      return null;
    }

    const getMultiselect = (value: any, options: SelectOption[]) => {
      return !isEmpty(value) ? value : options.map((x) => x.value);
    };

    const result = deepCopy(this.parameters());
    result.lob = getMultiselect(result.lob, this.searchConfig.lineOfBusiness);
    result.status = getMultiselect(result.status, this.searchConfig.status);
    result.payType =
      result.payType && result.payType.length
        ? getMultiselect(result.payType, this.searchConfig.payType)
        : [];

    result.alertType = getMultiselect(
      result.alertType,
      this.searchConfig.alertType
    );

    if (!this.dsVisible) {
      result.dsClaimType = null;
      result.dsClaimSubstatus = null;
    }

    result.dsClaimType = getMultiselect(
      result.dsClaimType,
      this.searchConfig.claimTypeDs
    );
    result.dsClaimSubstatus = getMultiselect(
      result.dsClaimSubstatus,
      this.searchConfig.claimSubstatusDs
    );

    if (!this.lvVisible) {
      result.lvClaimType = null;
      result.lvAbsenceType = null;
      result.lvClaimSubstatus = null;
      result.lvCausedBy = null;
    }

    result.lvClaimType = getMultiselect(
      result.lvClaimType,
      this.searchConfig.claimTypeLv
    );
    result.lvAbsenceType = getMultiselect(
      result.lvAbsenceType,
      this.searchConfig.absenceTypeLv
    );
    result.lvClaimSubstatus = getMultiselect(
      result.lvClaimSubstatus,
      this.searchConfig.absenceSubstatusLv
    );
    result.lvCausedBy = getMultiselect(
      result.lvCausedBy,
      this.searchConfig.causedBy
    );

    result.managerEmpUnum = result?.supervisor?.empUnum;
    if (result.startDate && result.startDate !== '') {
      result.startDate = new Date(result.startDate).toISOString();
    } else {
      result.startDate = null;
    }
    if (result.endDate && result.endDate !== '') {
      result.endDate = new Date(result.endDate).toISOString();
    } else {
      result.endDate = null;
    }

    return result;
  }

  /**
   * Writes search parameters to export's details sheet.
   * @param request
   */
  appendExport(request: ExportRequest) {
    const tryAddValue = (name: string, label: string) => {
      const value = this.formGroup.controls[name]?.value?.toString();
      if (value) {
        request.details.push({ label, value });
      }
    };

    request.details.push({
      label: 'Search Parameters',
      value: '',
      format: 'header',
    });
    tryAddValue('claimNumber', 'Claim Number');
    tryAddValue('firstName', 'First Name');
    tryAddValue('lastName', 'Last Name');
    tryAddValue('employeeId', 'Employee Id');
    tryAddValue('ssn', 'SSN');
    tryAddValue('lob', 'LOB');
    tryAddValue('status', 'Status');
    tryAddValue('startDate', 'Begin Date');
    tryAddValue('endDate', 'End Date');
    tryAddValue('supervisor', 'Supervisor');
    tryAddValue('includeIndirectReports', 'Include Indirect');

    if (this.dsVisible) {
      tryAddValue('dsClaimType', 'DS Claim Types');
      tryAddValue('dsClaimSubstatus', 'DS Substatuses');
    }

    if (this.lvVisible) {
      tryAddValue('lvClaimType', 'LV Claim Types');
      tryAddValue('lvAbsenceTypes', 'LV Absence Types');
      tryAddValue('lvClaimSubstatus', 'LV Substatuses');
      tryAddValue('lvCausedBy', 'LV Caused By');
    }
  }

  _clear() {
    this.parameters.set(null);
    this.formGroup.reset();
    this.lvVisible = false;
    this.dsVisible = false;
    if (this.config.initialSearchResultsOnClear) {
      this.searchClick();
    } else {
      this.clear.emit();
      srSpeak('Field/s  cleared', 'polite');
    }
  }

  searchClick() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    this.showEmptyMessage = false;
    if (this.formGroup.valid) {
      if (this.isEmpty() && !this.config.emptyFormAllowed) {
        this.showEmptyMessage = true;
        setTimeout(() => {
          this.searchElement.nativeElement.focus();
        }, 0);
        return;
      }
      srSpeak('Searching...', 'polite');
      const params = this.formGroup.value;
      this.parameters.set(params);
      this.search.emit(this);
    }
  }

  private isEmpty(): boolean {
    const v = this.formGroup.value;
    const hasValue =
      v.claimNumber ||
      v.firstName ||
      v.lastName ||
      v.startDate ||
      v.endDate ||
      v.employeeId ||
      v.ssn ||
      v.status ||
      v.supervisor ||
      v.lob ||
      v.payType;
    return !hasValue;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    if (this.config.key) {
      this.state.set(`${this.config.key}:expanded`, this.expanded);
    }
  }
}

export class DetailSearchConfiguration {
  claimNumber = new DetailSearchConfigurationItem(25);
  firstName = new DetailSearchConfigurationItem(25);
  lastName = new DetailSearchConfigurationItem(25);
  employeeId = new DetailSearchConfigurationItem(25);
  ssn = new DetailSearchConfigurationItem(15);
  lob = new DetailSearchConfigurationItem(25);
  alertType = new DetailSearchConfigurationItem(25, false);
  status = new DetailSearchConfigurationItem(25);
  startDate = new DetailSearchConfigurationItem(25);
  endDate = new DetailSearchConfigurationItem(25);
  supervisor = new DetailSearchConfigurationItem(25);
  includeIndirect = new DetailSearchConfigurationItem(25);
  payType = new DetailSearchConfigurationItem(25);
  initialValue: any;
  initialSearchMsg = 'Claim search results will appear here.';
  initialSearchIcon = 'search';
  initialSearchHeader = 'Search Results';

  /**
   * Shows the additional parameters toggle above the form
   */
  showParametersToggle = false;

  /**
   * Controls initial collapse/expand for parameters toggle. Defaults to true.
   */
  expanded = true;

  /**
   * Controls how far back the startDate can start.
   */
  maxBackDays: number = null;

  /**
   * Performs a search when the form is loaded
   */
  initialSearch = false;

  /**
   * Forces initial search to clear any previous parameter state
   */
  clearParameters = false;

  /**
   * Shows initial search results on clear button click
   * otherwise show empty state. initialSearch = true is required.
   */
  initialSearchResultsOnClear = false;

  /**
   * If false, require user to provide at least one search value.
   */
  emptyFormAllowed = true;

  key: string;

  managerLookupSupervisor: boolean = true;

  managerLookupOmni: boolean = true;

  /**
   * Override for server's value of maxSearchResults.
   * Only needed when the search's max results are
   * different from the default.
   */
  maxSearchResults: number = null;

  /**
   * Overrides visibility of controls based on roles. Set to false if
   * you need full control of display. Most forms should leave this true.
   */
  setDefaultVisibilitySettings: boolean = true;

  /**
   * Sets visibility for all fields. All fields display by default
   * so this is helpful when a form only needs to display a small
   * number of fields.
   */
  setVisibility(visible: boolean) {
    this.claimNumber.visible = visible;
    this.firstName.visible = visible;
    this.lastName.visible = visible;
    this.employeeId.visible = visible;
    this.ssn.visible = visible;
    this.lob.visible = visible;
    this.alertType.visible = visible;
    this.status.visible = visible;
    this.startDate.visible = visible;
    this.endDate.visible = visible;
    this.supervisor.visible = visible;
    this.includeIndirect.visible = visible;
    this.payType.visible = visible;
  }

  /**
   * Limits the LOB Options to Liability LOBs.
   * This is used by the Auditor 3rd Party Claim Search
   */
  limitLobsToLiability: boolean = false;
}

export class DetailSearchConfigurationItem {
  get classes(): string {
    return `tw-w-full md:tw-w-[${this.widthPercent}%]`;
  }

  constructor(public widthPercent: number, public visible: boolean = true) {}
}

export interface SearchRequest {
  getParameters(): any;
}
