import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  BaseComponent,
  LoadingState,
  MessageBus,
  SearchResult,
  TextAreaControlComponent,
  isBlank,
} from 'src/app/shared';
import { ClaimService, CommCenterService } from '../..';
import {
  CommCenterMessage,
  SaveMessageActionRequest,
  SendMessage,
} from '../../models/Communications';
import { ConversationSummary } from '../../models/ConversasationSummary';
import { emulator } from '../../services/emulator.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-comm-center2-messages',
  template: `
    <app-loading-panel
      [state]="loadingState"
      loadingStyle="Box"
      [loadingHeight]="400"
    >
      <div init class="tw-min-h-[400px] tw-pt-[100px]">
        <app-empty-state-label
          icon="message"
          header="View conversation"
          message="Select a claim to view or begin a conversation."
        >
        </app-empty-state-label>
      </div>
      <ng-template #content>
        @if(headerEnabled) {
        <div class="tw-flex tw-flex-wrap tw-w-full" title="">
          <div class="md:tw-block sm:tw-block sm:tw-w-[100%] md:tw-w-[50%]">
            <app-claimant-label value [claimant]="summary">
            </app-claimant-label>
          </div>
          <div class="md:tw-block sm:tw-block sm:tw-w-[100%]  md:tw-w-[50%]">
            <div class="mdfloatRight">
              <app-claim-label
                value
                [claim]="summary"
                layout="row"
                [disabled]="false"
                [hideWatchList]="true"
              ></app-claim-label>
            </div>
          </div>
          <div *ngIf="companionClaim">
            <app-toast type="Info">
              Your Leave claim is a companion to claim
              <a
                class="tw-text-white hover:tw-text-white hover:tw-decoration-white"
                routerLink="/claims"
                [queryParams]="{
                            claimId: companionClaim.claimId,
                            source: companionClaim.source,
                          }"
                >{{ companionClaim.claimNumber }}</a
              >. All messages sent on the Leave claim are centralized on the
              main claim.
            </app-toast>
          </div>
        </div>
        } @else if (companionClaim) {
        <div class="md:tw-block tw-hidden tw-w-[100%]">
          <app-toast type="Info">
            Your Leave claim is a companion to claim
            <a
              class="tw-text-white hover:tw-text-white hover:tw-decoration-white"
              routerLink="/claims"
              [queryParams]="{
                      claimId: companionClaim.claimId,
                      source: companionClaim.source,
                    }"
              >{{ companionClaim.claimNumber }}</a
            >. All messages sent on the Leave claim are centralized on the main
            claim.
          </app-toast>
        </div>
        }
        <div
          class="tw-pb-6"
          appOverflow
          [overflowHeight]="overflowHeight"
          [overflowScrollBottom]="true"
          #container
        >
          <div class="tw-flex tw-flex-wrap tw-w-full" title="">
            <div class="tw-w-full  tw-pt-6 "></div>

            <ng-container *ngIf="!isEmulating">
              <div
                class="examiner-container app-pb2  tw-w-full tw-flex"
                *ngFor="let message of messages"
                id="contentScroll"
              >
                <div class="tw-flex-none">
                  <mat-icon class="icon">{{
                    message.examiner ? 'support_agent' : 'face'
                  }}</mat-icon>
                </div>
                <div class="tw-flex-auto">
                  <div class="sender">
                    {{ getSenderDisplay(message) + ' - ' | titlecase }}
                    {{ message.date | timeSince }}
                    <span class="app-indicator tw-ml-1" *ngIf="message.unread"
                      >NEW</span
                    >
                    <span class="app-indicator tw-ml-1" *ngIf="message.read"
                      >READ</span
                    >
                    <div class="messageDate">
                      {{ message.date | date : 'M/d/yyyy h:mm a' }}
                    </div>
                  </div>

                  <div class="tw-flex">
                    <div
                      class="message-div tw-grow-0 text-breaking"
                      [ngClass]="{
                        'examiner-message': message.examiner,
                        'user-message': !message.examiner
                      }"
                    >
                      {{ message.value }}
                    </div>
                    <div class="tw-grow"></div>
                  </div>
                </div>
              </div>
            </ng-container>

            @if(canSendMessage) {
            <div class="app-pt2 tw-w-full" *ngIf="messages?.length === 0">
              <app-empty-state-label icon="mail_outline" header="No messages">
                <app-toast>{{ startConvoMessage }} </app-toast>
              </app-empty-state-label>
            </div>
            <div class="app-pt2 tw-w-full">
              <form [formGroup]="formGroup">
                <app-text-area-control
                  id="commCenterInput"
                  label="Type a message..."
                  ngDefaultControl
                  [formGroup]="formGroup"
                  formControlName="message"
                  [maxLength]="1000"
                  [rows]="2"
                  (keypress)="onKeyPressEvent($event)"
                  [disabled]="isSending"
                  #messageTextarea
                  [ariaDescribedBy]="'commCenterInputError'"
                ></app-text-area-control>
                <div *ngIf="this.errorMessage !== ''">
                  <span
                    id="commCenterInputError"
                    class="errorMsg"
                    [attr.aria-live]="
                      this.errorMessage ? 'assertive' : 'polite'
                    "
                  >
                    {{ this.errorMessage }}</span
                  >
                </div>

                <div class="tw-pr-2 tw-w-full">
                  <app-toast type="Info" *ngIf="sentSuccess === true">
                    {{ this.thankyouToast }}
                  </app-toast>
                </div>
              </form>
              <app-action-row>
                <app-button
                  right2
                  autofocus
                  (click)="sendMsg()"
                  emphasis="High"
                  [id]="'sendMessage'"
                  [loading]="isSending"
                  >Send</app-button
                >

                <app-button
                  right1
                  id="btnCancel"
                  (click)="clearMsg()"
                  emphasis="Low"
                  >Clear</app-button
                >
              </app-action-row>
            </div>

            } @else {
            <div class="app-pt2 tw-w-full">
              <app-empty-state-label icon="mail_outline" header="No messages">
                <app-toast
                  >For additional information regarding closed claims, please
                  contact your {{ examinerLabel }}. {{ contactNumber }}
                </app-toast>
              </app-empty-state-label>
            </div>
            }
          </div>
        </div>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .contentScroll {
        overflow-y: scroll;
        background-color: #f0f4f7;
      }

      .errorMsg {
        font-weight: bold;
        color: #c24533 !important;
      }
      .errorMsg:focus {
        color: var(--color-warn);
        font-weight: bold;
        outline-color: var(--color-warn) !important;
      }
      ::ng-deep .commCenterName {
        font-weight: bold;
      }
      .mdfloatRight {
        float: right;
      }
      @media only screen and (max-device-width: 480px) {
        .icon {
          display: none;
        }

        .mdfloatRight {
          float: none;
        }
        ::ng-deep .commactionbtn {
          padding-right: 30px !important;
        }
      }

      .messageDate {
        float: right;
        margin-right: 10px;
      }
      .examiner-container {
      }
      .icon {
        font-size: 40px;
        width: 40px;
        height: 40px;
        color: var(--color-accent);
        margin-top: 18px;
      }
      .user-message {
        background-color: #e9edf2;
        border-radius: 0 8px 8px;
        margin-left: 8px;
      }
      .message-div {
        margin-right: 10px;
        padding: 12px 16px 12px 16px;
      }
      .examiner-message {
        background-color: var(--color-sedgwick-blue);
        color: white;
        border-radius: 0 8px 8px;
        margin-left: 8px;
      }
      .sender {
        font-size: 12px;
        margin-left: 24px;
        font-weight: bold;
        padding-bottom: 4px;
        color: var(--color-input-grey);
      }

      .text-breaking {
        word-break: break-all;
      }
    `,
  ],
})
export class CommCenter2MessagesComponent
  extends BaseComponent
  implements OnInit, AfterViewChecked
{
  @ViewChild('container', { static: false })
  container: ElementRef;

  @ViewChild('messageTextarea')
  messageTextArea: TextAreaControlComponent;

  @Input()
  claim: SearchResult;

  @Input()
  summary: ConversationSummary;

  @Input()
  companionClaim: SearchResult = null;

  @Input()
  headerEnabled = true;

  @Input()
  overflowHeight: string = null;

  @Input()
  focus: boolean;

  @Output()
  conversationUpdated: EventEmitter<ConversationSummary> = new EventEmitter();

  messages: CommCenterMessage[];

  examinerLabel: string;

  contactNumber: string;

  formGroup = this.fb.group({
    date: [],
    sender: [],
    message: [],
    read: [],
  });

  sentSuccess: boolean = false;

  isSending: boolean = false;

  errorMessage: string = '';

  isEmulating: boolean = false;

  canSendMessage: boolean;

  loadingState: LoadingState = 'Init';

  messageAreaFocusRequested: boolean = false;

  thankyouToast: string =
    'Thank you for your message. It has been sent and a response will be provided here within one business day.';

  startConvoMessage: string =
    'Send a message to start a conversation for this claim';

  constructor(
    private fb: UntypedFormBuilder,
    private commCenterService: CommCenterService,
    private alias: InpageAliasService,
    private enumalatorService: emulator,
    private session: Session,
    public userRole: UserRoleService,
    private claimService: ClaimService,
    private messageBus: MessageBus
  ) {
    super();
    this.subs.sink = this.messageBus
      .topic('comm-center-messages:focus')
      .subscribe(() => {
        this.messageAreaFocusRequested = true;
        //this.messageTextArea?.focus();
      });
  }

  async ngOnInit() {
    this.examinerLabel = await this.alias.get('examinerSingular');
    this.contactNumber = await this.alias.get('phone');
    this.isEmulating = this.session.user?.emulatorContext?.isEmulating;

    if (this.summary) {
      await this.loadConversationMessages(this.summary);
    } else {
      // no conversation exists yet, need to create new conv depending on if userowns or not

      if (this.claim) {
        this.summary = await this.commCenterService.getClaimConversation(
          this.claim.source,
          this.claim.claimId
        );

        // starting conversation on claim from summary page that has no previous messages
        if (this.claim.userOwns) {
          // create new conversation under myconversations on service
          this.addNewConversation(this.commCenterService.myConversations);
        } else {
          // create new conversation under managerconversations on service
          this.addNewConversation(this.commCenterService.managerConversations);
        }
      }
    }
  }

  ngAfterViewChecked() {
    // adding this because the focus doesnt shift on the first click after page load
    if (this.messageAreaFocusRequested) {
      this.messageTextArea?.focus();
      this.messageAreaFocusRequested = !this.messageAreaFocusRequested;
    }
  }

  addNewConversation(convArray: ConversationSummary[]) {
    const existingConv = convArray.filter(
      (m) => m.claimId == this.claim.claimId && m.source == this.claim.source
    );
    if (existingConv.length > 0) {
      this.summary = existingConv[0];
      if (this.summary.sourceClaimKey) {
        this.companionClaim = this.summary.sourceClaimKey;
      }
    } else {
      const newConv = new ConversationSummary();
      if (
        (this.claim.source === 'T' || this.claim.source === 'J') &&
        this.claim.companionClaims?.length > 0
      ) {
        // NEED TO LOOK UP PRIMARY CLAIM FROM SELECTED CLAIM INFO
        const firstJurisCompClaim = this.claim.companionClaims.filter(
          (cc) => cc.recordSource == (this.claim.source === 'T' ? 'J' : 'T')
        );
        if (firstJurisCompClaim?.length > 0) {
          const jClaim = firstJurisCompClaim[0];
          this.companionClaim = {
            claimId: jClaim.claimUid,
            claimNumber: jClaim.fileNum,
            source: jClaim.recordSource,
          } as any;

          // add new conversation data if needed for UI
        }
      }

      newConv.claim = this.claim;

      newConv.claimId = this.claim.claimId;
      newConv.claimDescription = this.claim.claimDescription;
      newConv.source = this.claim.source;
      newConv.claimNumber = this.claim.claimNumber;
      newConv.name = this.claim.name;
      newConv.details = [];
      newConv.empId = this.claim.empId;

      newConv.empUnum = this.claim.empUnum;

      //curConv = newConv;
      convArray.push(newConv);
      this.summary = newConv;
      //this.conversationsList.updateDataSource();
    }
  }

  async loadConversationMessages(summary: ConversationSummary) {
    this.loadingState = 'Loading';
    this.companionClaim = null;

    if (
      summary.sourceClaimKey &&
      summary.claimId != summary.sourceClaimKey.claimId
    ) {
      this.companionClaim = summary.sourceClaimKey;
    }
    this.sentSuccess = false;
    this.errorMessage = null;
    this.summary = summary;

    this.messages = summary.details ?? (summary.details = []);
    this.markUnreadMessagesAsRead();
    this.formGroup.controls['message'].setValue(null);
    const claimstatus = this.summary.claim?.status ?? this.summary.status;
    this.canSendMessage = !claimstatus?.toLowerCase().includes('closed');
    this.loadingState = 'Loaded';

    if (this.focus) {
      this.focus = false;
      setTimeout(() => {
        this.messageTextArea?.focus();
      }, 0);
    }
  }

  onKeyPressEvent(event: any) {
    if (event.target.value == '\n') {
      this.formGroup.controls['message'].setValue('');
    }
  }

  async sendMsg() {
    if (this.isSending) {
      return;
    }

    this.sentSuccess = false;
    this.errorMessage = '';
    const msgText = this.messageTextArea.formControl.value;
    if (isBlank(msgText)) {
      this.errorMessage = 'Error : Please enter a message';
      this.messageTextArea.focus();
      //srSpeak(this.errorMessage, 'polite');
      return;
    }

    this.isSending = true;
    this.enumalatorService.showAuditorInfo();
    const targetClaim = this.companionClaim ?? this.summary;
    const message = new CommCenterMessage();
    const request = new SendMessage();
    if (this.summary.messageId == null) {
      request.messageId = undefined;
    } else {
      request.messageId = Array.isArray(this.summary.messageId)
        ? this.summary.messageId[0]
        : this.summary.messageId;
    }
    //console.log(request.messageId);
    request.messageText = msgText;
    request.empUNum = targetClaim.empUnum;
    request.userOwns = targetClaim.userOwns;
    request.claimNumber =
      this.companionClaim?.claimNumber ?? this.summary.claimNumber;
    request.createdTS = new Date();

    const results = await this.commCenterService.saveMessage(
      targetClaim.source,
      targetClaim.claimId,
      targetClaim.lob,
      request
    );

    this.summary.messageId = results.messageId;
    message.examiner = false;
    message.value = request.messageText;
    message.date = new Date(results.date);
    message.sender = results.name;
    this.summary.details.push(message);

    this.summary.date = new Date(message.date);
    this.sentSuccess = true;
    srSpeak(this.thankyouToast, 'polite');
    this.messageTextArea.formControl.reset();
    this.messageTextArea.focus();
    this.isSending = false;
    this.conversationUpdated.emit();

    setTimeout(() => {
      this.container.nativeElement.scrollTo(
        0,
        this.container.nativeElement.scrollHeight
      );
    }, 0);
  }

  clearMsg() {
    this.messageTextArea.formControl.reset();
    this.messageTextArea.focus();
  }

  markUnreadMessagesAsRead() {
    for (const message of this.messages) {
      if (message.unread) {
        const request: SaveMessageActionRequest =
          new SaveMessageActionRequest();
        request.ClaimNumber = this.summary.claimNumber;
        request.ContNum = this.summary.contNum;
        request.CorrelationId = message.correlationId;
        request.ConversationDetailId = message.detailId;
        request.MessageId = this.summary.messageId[0];
        request.SourceSystem = this.summary.source;

        this.commCenterService
          .readMessage(
            this.summary.source,
            this.summary.claimId,
            this.summary.lob,
            request
          )
          .then(() => {
            const isEmulating =
              this.session.user &&
              this.session.user?.emulatorContext &&
              this.session.user?.emulatorContext?.isEmulating;

            if (!isEmulating) {
              message.unread = false;
              message.read = true;
              this.summary.unread =
                this.summary.details.filter((fd) => fd.unread)?.length > 0;
              this.commCenterService.updateUnreadCount();
            }
          });
      }
    }
  }

  getSenderDisplay(message: CommCenterMessage) {
    if (!message.examiner) {
      const lastSenderSeparater = message.sender.lastIndexOf(' ');
      if (lastSenderSeparater > 0) {
        const firstSenderPart = message.sender.substring(
          0,
          lastSenderSeparater
        );

        const lastSenderPart =
          message.sender.substring(
            lastSenderSeparater + 1,
            lastSenderSeparater + 2
          ) + '.';

        return firstSenderPart + ' ' + lastSenderPart;
      }
    }

    return message.sender;
  }
}
